import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.css"
import { rhythm, scale } from "../utils/typography"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import { fluidRange } from "polished"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Title = styled.h1`
  margin-top: ${rhythm(2)};
  margin-bottom: ${rhythm(2.5)};
  color: var(--BLUE_TEXT);
  line-height: 1.4;
  ${fluidRange({
    prop: "font-size",
    fromSize: "2.2rem",
    toSize: "3rem",
  })}
`

const Author = styled.em`
  color: var(--CAPPUCCINO_SHADE);
  font-weight: bold;
`

const ArticlesNavUl = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: ${rhythm(1)};

  &:last-child {
    text-align: right;
  }
  @media (min-width: ${breakpoints.phoneUp}) {
    display: flex;
    flex-wrap: wrap;

    li:first-child {
      margin-right: 4rem;
    }
  }
`

const FooterNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article>
          <header style={{ marginBottom: "3rem" }}>
            <Title> {post.frontmatter.title} </Title>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Author> By {post.frontmatter.author} </Author>
              <span
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  // marginBottom: rhythm(1),
                  color: "var(--CAPPUCCINO_SHADE)",
                }}
              >
                {post.frontmatter.date}
              </span>
            </p>
          </header>
          {/*           <section
            className="article-content"
            dangerouslySetInnerHTML={{
              __html: post.body,
            }}
          /> */}
          {/*           <section className="article-content">
            {renderAst(post.mdxAST)}
          </section> */}

          <MDXRenderer>{post.body}</MDXRenderer>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <section>
            <Bio />
          </section>
        </article>
        <FooterNav>
          <ArticlesNavUl>
            <li>
              {previous && (
                <Link
                  to={`/blog/${previous.fields.slug}`}
                  rel="prev"
                  className="highlight--yellow"
                >
                  <span
                    style={{
                      paddingRight: ".5rem",
                    }}
                  >
                    ←
                  </span>
                  {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link
                  to={`/blog/${next.fields.slug}`}
                  rel="next"
                  className="highlight--yellow"
                >
                  {next.frontmatter.title}
                  <span
                    style={{
                      paddingLeft: ".5rem",
                    }}
                  >
                    →
                  </span>
                </Link>
              )}
            </li>
          </ArticlesNavUl>
        </FooterNav>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      # markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      # html
      body
      # mdxAST
      frontmatter {
        title
        date(formatString: "YYYY年 M月 D日")
        description
        author
      }
    }
  }
`
